const shareData = {
	url: 'http://meltedsnow.me/',
	title: "Meltedsnow",
	description: 'Антон Раннала. Домашняя страница.',
	image: 'http://meltedsnow.me/assets/images/author/author.jpg'   
}

function share() {
	const shareNode = document.getElementById("share");
	let xhr = new XMLHttpRequest();
	xhr.open('GET', 'https://yastatic.net/share2/share.js');
	xhr.send();
	xhr.onload = function() {
		eval(xhr.response);
		Ya.share2(shareNode, shareData);
	}
}

export default share;