exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_PURE_IMPORT_0___ = require("./OpenSans-Regular.woff2");
var ___CSS_LOADER_URL_PURE_IMPORT_1___ = require("./OpenSans-Regular.woff");
var ___CSS_LOADER_URL_PURE_IMPORT_2___ = require("./OpenSans-SemiBold.woff2");
var ___CSS_LOADER_URL_PURE_IMPORT_3___ = require("./OpenSans-SemiBold.woff");
var ___CSS_LOADER_URL_PURE_IMPORT_4___ = require("./OpenSans-Italic.woff2");
var ___CSS_LOADER_URL_PURE_IMPORT_5___ = require("./OpenSans-Italic.woff");
var ___CSS_LOADER_URL_PURE_IMPORT_6___ = require("./OpenSans-Bold.woff2");
var ___CSS_LOADER_URL_PURE_IMPORT_7___ = require("./OpenSans-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_PURE_IMPORT_0___);
var ___CSS_LOADER_URL_IMPORT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_PURE_IMPORT_1___);
var ___CSS_LOADER_URL_IMPORT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_PURE_IMPORT_2___);
var ___CSS_LOADER_URL_IMPORT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_PURE_IMPORT_3___);
var ___CSS_LOADER_URL_IMPORT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_PURE_IMPORT_4___);
var ___CSS_LOADER_URL_IMPORT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_PURE_IMPORT_5___);
var ___CSS_LOADER_URL_IMPORT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_PURE_IMPORT_6___);
var ___CSS_LOADER_URL_IMPORT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_PURE_IMPORT_7___);
// Module
exports.push([module.id, "@font-face {\n    font-family: 'Open Sans';\n    src: url(" + ___CSS_LOADER_URL_IMPORT_0___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL_IMPORT_1___ + ") format('woff');\n    font-weight: normal;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Open Sans';\n    src: url(" + ___CSS_LOADER_URL_IMPORT_2___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL_IMPORT_3___ + ") format('woff');\n    font-weight: 600;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Open Sans';\n    src: url(" + ___CSS_LOADER_URL_IMPORT_4___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL_IMPORT_5___ + ") format('woff');\n    font-weight: normal;\n    font-style: italic;\n}\n\n@font-face {\n    font-family: 'Open Sans';\n    src: url(" + ___CSS_LOADER_URL_IMPORT_6___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL_IMPORT_7___ + ") format('woff');\n    font-weight: bold;\n    font-style: normal;\n}\n\n", ""]);
