const photo = {
	node: document.querySelector(".js-about-photo"),
	activityMarker: "about__photo_increased",
	state: false,

	isActive() {
		return this.state;
	},

	isEqualTo(anything) {
		return this.node === anything;
	},

	activate() {
		this.node.classList.add(this.activityMarker);
		this.state = true;
	},

	deactivate() {
		this.node.classList.remove(this.activityMarker);
		this.state = false;	
	}
}

function managePhoto(e) {
	if(photo.isEqualTo(e.target)) {
		if(photo.isActive()) {
			photo.deactivate();
		} else {
			photo.activate();
		}
	} else {
		if(photo.isActive()) {
			photo.deactivate();
		}
	}
}

function scalePhoto() {
	document.removeEventListener("click", managePhoto);	
	document.addEventListener("click", managePhoto);	
}

export default scalePhoto;