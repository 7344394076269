import isTouchDevice from "./isTouchDevice.js";

const detectors = [
	{
		detector: isTouchDevice,
		dataAttribute: "data-touch"
	}
];

function applyDetectors() {
	detectors.forEach(function(item) {
		document.body.setAttribute(
			item.dataAttribute,
			item.detector()	
		)
	});	
}

export default applyDetectors;
