/**
 * Application entry point
 */

import Author from "images/author/author.jpg";

// Load application styles
import 'fonts.css'; 
import 'styles/styles.scss';

// ================================
// START YOUR APP HERE
// ================================
import share from "./share.js";
import writeMailAsText from "./writeMailAsText.js";
import showInitiallyHidden from "./showInitiallyHidden.js";
import scalePhoto from "./scalePhoto.js";
import applyDetectors from "./detectors/applyDetectors.js";
import applyTypography from "./applyTypography.js";

writeMailAsText();
share();
scalePhoto();
applyDetectors();
showInitiallyHidden();
applyTypography();


