import each from "lodash/each";
import Typograf from "typograf";
const tp = new Typograf({locale: ['ru', 'en-US']});

function applyTypography() {
	let typographyNodes = document.querySelectorAll(".js-apply-typography");
	each(typographyNodes, function(item) {
		item.innerHTML = tp.execute(item.innerHTML);
	});
}

export default applyTypography;